<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <a href="#"> <i class="uil-home-alt"></i> </a>
            </li>
            <li>{{ $t("evaluation_survey.surveys") }}</li>
            <li>{{ $t("evaluation_survey.edit") }}</li>
          </ul>
        </nav>
      </div>

      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>

      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>

      <div class="card mt-4 position-relative">

        <div v-if="loading"
          style="position: absolute; height: 100%; width: 100%; z-index: 999; background: rgba(238, 238, 238, 0.5); display: flex; justify-content: center; align-items: center">
          <div uk-spinner="ratio: 4" class="text-info"></div>
        </div>

        <div class="card-body">
          <h4><i class="uil-question-circle"></i> {{ $t("evaluation_survey.edit") }}</h4>
          <hr />
          <div class="row">
            <div class="col-xl-9 m-auto">
              <form action="" novalidate="novalidate">
                <div class="form-group row mb-3 align-items-center">
                  <label class="col-md-3 col-form-label" for="course_title">{{
                    $t("evaluation_survey.name")
                  }}<span class="required">*</span></label>
                  <div class="col-md-9">
                    <input type="text" class="form-control mb-0" id="course_title" name="title" v-model="survey.name"
                      :placeholder="$t('survey.enter_name')" required="" />
                  </div>
                </div>


                <div class="d-flex justify-content-end  align-items-center">
                  <toggle-button :width="120" color="#ae71ff" v-model="survey.is_default" :sync="true"
                    :labels="{ checked: $t('evaluation_survey.default'), unchecked: $t('evaluation_survey.not_default') }" />
                </div>
                <div class="d-flex align-content-center justify-content-end table-vertical-center align-items-center">
                  <toggle-button :width="120" color="#ae71ff" v-model="survey.is_active" :sync="true"
                    :labels="{ checked: $t('evaluation_survey.active'), unchecked: $t('evaluation_survey.inactive') }" />
                </div>

                <hr />

                <div class="questions-area">

                  <div v-for="(question, index) in questions" class="question-item">
                    <div class="d-flex justify-content-between">
                      <h4>{{ $t('evaluation_survey.question') }} #{{ index + 1 }}</h4>
                      <button type="button" @click="deleteQuestion(index, question.id)"
                        class="btn btn-outline-danger btn-sm"><i class="uil-trash"></i> {{
                          $t("evaluation_survey.delete_question") }}</button>
                    </div>
                    <hr>
                    <div class="form-group row mb-3 align-items-center">
                      <label class="col-md-3 col-form-label" :for="'question_name_' + index">
                        {{ $t('evaluation_survey.question_name') }} <span class="required">*</span>
                      </label>
                      <div class="col-md-9">
                        <input type="text" v-model="question.name" :id="'question_name_' + index"
                          :placeholder="$t('evaluation_survey.question_name')" class="mb-0 form-control">
                      </div>
                    </div>
                    <div class="form-group row mb-3 align-items-center">
                      <label class="col-md-3 col-form-label">{{ $t('evaluation_survey.question_type') }}</label>
                      <div class="col-md-9">
                        <span v-if="question.type === 1">Çoktan seçmeli</span>
                        <span v-if="question.type === 2">{{ $t('evaluation_survey.type_textarea') }}</span>
                      </div>
                    </div>

                    <div v-if="question.type === 1">
                      <hr>
                      <h5>{{ $t('evaluation_survey.options') }}</h5>
                      <hr>
                      <div v-for="(option, optionIndex) in question.options">
                        <div class="d-flex justify-content-start">
                          <div class="p-2 ">
                            <label style="font-size: 13px">{{ $t('evaluation_survey.option_label') }} #{{ optionIndex +
                              1
                              }}</label>
                            <input type="text" v-model="option.label" class="form-control mb-0">
                          </div>
                          <div class="p-2">
                            <label style="font-size: 13px">{{ $t('evaluation_survey.option_point') }} #{{ optionIndex +
                              1
                              }}</label>
                            <select v-model="option.point" class="mb-0">
                              <option :value="option.point">{{ option.point }}</option>
                            </select>
                          </div>
                          <div class="p-2" v-if="optionIndex > 1">
                            <button class="uk-button uk-text-warning" style="margin-top: 1.8rem;"
                              @click="deleteOption(optionIndex, index)"><i class="uil-trash"></i></button>
                          </div>
                        </div>
                      </div>
                      <div class="p-2">
                        <button v-if="question.options.length < 5" class="uk-button uk-button-primary small"
                          type="button" @click="addOption(index)">
                          <i class="uil-plus m-0"></i>{{ $t('general.add_option') }}
                        </button>
                      </div>
                    </div>

                    <div v-if="question.type === 2">
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-between">
                  <select v-model="selected_question_type" style="max-width: 200px;" class="mx-1">
                    <option :value="1">Çoktan seçmeli</option>
                    <option :value="2">{{ $t('evaluation_survey.type_textarea') }}</option>
                  </select>
                  <input type="button" @click="addQuestion" class="btn btn-lg btn-outline-primary"
                    :value="$t('evaluation_survey.add_question')" />
                </div>
                <hr>
                <div class="uk-card-footer d-flex justify-content-end pb-0 pr-0 align-items-center">
                  <button class="uk-button uk-button-primary small" type="button" @click="saveForm">
                    <i class="icon-feather-arrow-right"></i> {{ $t("general.save") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import module, {
  BASE_URL,
  MODULE_NAME,
  GET_ITEM,
  UPDATE_ITEM,
  DELETE_QUESTION,
  ITEM,
  LOADING,
  ERROR,
  SUCCESS
} from "@/core/services/store/evaluation_survey.module";
import store from "@/core/services";

export default {
  name: "EvaluationSurveyEdit",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(MODULE_NAME, module)
  },
  data() {
    return {
      errorMessages: [],
      successMessage: null,
      selected_question_type: 1, // 1: dropdown, 2: textarea
      //questions: [],
      // name: null,
      // is_active: true,
      // is_default: false,
    }
  },
  computed: {
    loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    questions() {
      let self = this;
      let result = [];

      if (typeof self.survey.questions !== "undefined") {
        self.survey.questions.forEach(question => {
          //let s = question.options && Array.isArray(question.options) ? question.options : JSON.parse(question.options);
          return result.push({
            id: question.id,
            name: question.name,
            type: question.type,
            options: question.options && Array.isArray(question.options) ? question.options : JSON.parse(question.options)
          })
        });
      }
      return result;
    },
    survey: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    success: {
      get() {
        return store.getters[MODULE_NAME + "/" + SUCCESS];
      },
      set(value) { },
    },
  },
  methods: {
    deleteOption(optionIndex, questionIndex) {
      !Array.isArray(this.survey.questions[questionIndex]['options']) ? this.survey.questions[questionIndex]['options'] = JSON.parse(this.survey.questions[questionIndex]['options']) : null;
      this.survey.questions[questionIndex]['options'].splice(optionIndex, 1)
    },
    addOption(questionIndex) {
      !Array.isArray(this.survey.questions[questionIndex]['options']) ? this.survey.questions[questionIndex]['options'] = JSON.parse(this.survey.questions[questionIndex]['options']) : null;
      this.survey.questions[questionIndex]['options'].push({
        label: null,
        point: this.survey.questions[questionIndex]['options'].length + 1,
      })
    },
    saveForm() {
      let self = this;
      let validated = true;
      if (self.survey.name == null || self.survey.name.length < 3) {
        this.errorMessages.push(
          self
            .$t("validation.required_field")
            .replace(":NAME", self.$t("evaluation_survey.name"))
        );
        self.scrollToTop();
        validated = false;
      }

      if (self.questions.length === 0) {
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            self.$t("evaluation_survey.questions")
          )
        );
        self.scrollToTop();
        validated = false;
      }

      self.questions.forEach(question => {
        if (question.name == null) {
          this.errorMessages.push(
            self
              .$t("validation.required_field")
              .replace(":NAME", self.$t("evaluation_survey.question_name"))
          );
          self.scrollToTop();
          validated = false;
        }
        question.options.forEach(option => {
          if (option.label == null) {
            this.errorMessages.push(
              self
                .$t("validation.required_field")
                .replace(":NAME", self.$t("evaluation_survey.option_label"))
            );
            self.scrollToTop();
            validated = false;
          }
        });
      });

      let questions = [];
      //let selfQuestions = Object.assign([], self.questions);

      self.questions.forEach(question => {
        let selfQuestion = Object.assign({}, question);
        selfQuestion.options = JSON.stringify(question.options);

        questions.push(selfQuestion);
      });
      if (validated) {
        store
          .dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
            url: BASE_URL + "/" + self.$route.params.id,
            contents: {
              name: self.survey.name,
              is_active: self.survey.is_active,
              is_default: self.survey.is_default,
              questions: questions
            }
          });
      }
    },
    addDropdownQuestion() {
      let question = {
        id: 0,
        name: null,
        type: 1,
        options: [
          {
            label: null,
            point: 1,
          },
          {
            label: null,
            point: 2,
          }
        ]
      };
      this.survey.questions.push(question);
    },
    addTextareaQuestion() {
      let question = {
        id: 0,
        name: null,
        type: 2,
        options: []
      };
      this.survey.questions.push(question);
    },
    addQuestion() {
      switch (this.selected_question_type) {
        case 1:
          this.addDropdownQuestion();
          break;
        case 2:
          this.addTextareaQuestion();
          break;
      }
    },
    deleteQuestion(index, id) {
      if (id > 0) {
        store.dispatch(MODULE_NAME + '/' + DELETE_QUESTION, {
          url: BASE_URL + '/delete-question/' + id
        })
        this.survey.questions.splice(index, 1);
      }
      else {
        this.survey.questions.splice(index, 1);
      }
    },
    getItem() {
      store.dispatch(MODULE_NAME + '/' + GET_ITEM, {
        url: BASE_URL + '/' + this.$route.params.id,
      })
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    survey: function (val) {
      this.survey.is_active = parseInt(val.is_active) === 1;
      this.survey.is_default = parseInt(val.is_default) === 1;
    },
    success: function (val) {
      if (val) {
        this.successMessage = this.$t("general.successfully_updated");
        this.$router.push({ name: "company.evaluation-surveys.list" });
      }
    },
    error: function (val) {
      if (val) {
        this.scrollToTop();
        this.errorMessages.push(val);
      }
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  mounted() {
    this.getItem();
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 5000);
  },
}
</script>

<style scoped>
.question-item {
  background: rgb(251, 251, 251);
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #efefef;
}
</style>